@import "styles/functions.scss";

.section-container {
  font-style: normal;
}

.section-title {
  font-weight: bold;
  font-size: rem(52px);
  line-height: rem(73px);
  color: var(--main-black);

  @media (max-width: 600px) {
    font-size: rem(32px);
    line-height: rem(53px);
    text-align: center;
  }
}

.section-text {
  font-weight: normal;
  font-size: rem(18px);
  line-height: rem(36px);
  color: var(--gray-one);

  @media (max-width: 600px) {
    font-size: rem(14px);
    line-height: rem(32px);
    text-align: center;
  }
}