@import "@fontsource/plus-jakarta-sans";

body,
input,
textarea,
button {
  font-family: 'Izmir', sans-serif;
  color: var(--main-black);
}

p {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: normal;
  color: var(--gray-one);
}