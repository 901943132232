@import "styles/functions.scss";

.button {
  padding: rem(16px) rem(50px);
  height: rem(63px);
  width: max-content;
  background-color: var(--main-black);
  border-radius: rem(6px);
  color: var(--main-white);
  font-size: rem(22px);
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    color: var(--main-black);
    background-color: var(--main-white);
    border: rem(3px) solid var(--main-black);
    transition: color .4s;
  }
}