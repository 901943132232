.plugbunny {
  padding-bottom : 50px;
  .logo {
    width : 90px;
    border-radius: 20px;
    margin-right : 10px
  }

  p {
    line-height: 24px;
  }

  a {
    color : #333;
    text-decoration: underline;
    font-weight: bold;
  }

  strong {
    color : #05BFDB;
    padding : 10px 0;
    display: inline-block;
  }

  .app-information {
    display:flex;
    align-items: center;
  }

  .app-support {
    border-top: 5px dashed #333;
    margin-top : 40px;
    padding-top  :40px;
    align-items: center;
    h1 {
      font-size: 54px;
    }
  }
}