@import "styles/functions.scss";

body {
  &.dark {

    .box-container,
    .partner-box {
      background-color: var(--main-black);

      span {
        color: var(--main-white);
      }

      svg {
        fill: var(--main-white);
      }
    }

    .member-social {
      background-color: var(--main-black);

      a {
        color: var(--main-white);
      }
    }

    .social-media {
      a {
        background-color: var(--main-black);

        svg {
          fill: var(--main-white);
        }
      }
    }

    .button {
      background-color: var(--main-white);
      color: var(--main-black);
      border: rem(3px) solid var(--main-black);

      &:hover {
        background-color: var(--main-black);
        color: var(--main-white);
        border: none;
      }
    }

    .footer {
      .left-side {
        h4 {
          color: var(--gray-three);
        }
      }
    }
  }
}