@import "styles/functions.scss";

.navigation {
  display: contents;

  @media (max-width: 600px) {
    display: none;
  }

  nav {
    display: flex;
    align-items: center;
    gap: rem(35px);
    overflow: auto;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;

    @media (max-width: 600px) {
      gap: rem(8px);
      width: 150px;
    }

    a {
      text-decoration: none;
      font-size: rem(12px);
      font-weight: bold;
      font-style: normal;
      color: var(--main-black);
      text-transform: uppercase;
      position: relative;

      &.active {
        &::before {
          content: "";
          position: absolute;
          width: rem(6px);
          height: rem(6px);
          background-color: var(--main-black);
          border-radius: rem(100px);
          bottom: rem(-8px);
          left: 40%;
        }
      }

      &:hover {
        opacity: .8;
        transition: .4s;
      }
    }
  }
}