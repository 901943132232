@font-face {
  font-family: 'Izmir';
  src: url('./Izmir-Thin-100.otf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Izmir';
  src: url('./Izmir-Extralight-200.otf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Izmir';
  src: url('./Izmir-Light-300.otf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Izmir';
  src: url('./Izmir-Regular-400.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Izmir';
  src: url('./Izmir-Medium-500.otf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Izmir';
  src: url('./Izmir-Semibold-600.otf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Izmir';
  src: url('./Izmir-Bold-700.otf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Izmir';
  src: url('./Izmir-Extrabold-800.otf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Izmir';
  src: url('./Izmir-Black-900.otf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
