@import "styles/functions.scss";

html {
  scroll-behavior: smooth;
}


body {
  height: 100%;
  background-color: var(--main-white);
  width: rem(1440px);
  max-width: 100%;
  margin: 0 auto;
  padding: 0 8px;
}

.app-container {
  min-height: calc(100vh - rem(120px));
}

.box-container {
  background-color: var(--gray-five);
  border-radius: rem(40px);
  padding: rem(30px);

  &:nth-child(1) {
    width: rem(200px);
    height: rem(200px);
    align-self: end;
  }

  &:nth-child(2) {
    width: rem(300px);
    height: rem(300px);
  }

  &:nth-child(3) {
    width: rem(260px);
    height: rem(250px);
  }

  &:nth-child(4) {
    width: rem(170px);
    height: rem(170px);
  }
}

.box-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;

  span {
    font-size: rem(14px);
    font-weight: bold;
    line-height: rem(19px);
  }
}