@import "styles/functions.scss";

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--main-white);
  height: rem(70px);
  border-bottom: rem(1px) solid var(--gray-five);
  position: sticky;
  top: 0;
  z-index: 5000;

  @media (max-width: 600px) {
    justify-content: center;
  }
}

@keyframes example {
  from {
    transform: rotate(0)
  }

  to {
    transform: rotate(1turn)
  }
}

.anim-rad-360 {
}

.anim-360-self {

}