@import "styles/functions.scss";

.footer {
  background-color: var(--main-black);
  max-width: rem(1170px);
  height: rem(337px);
  border-top-left-radius: rem(10px);
  border-top-right-radius: rem(10px);
  margin: 0 auto;
  display: flex;

  @media (max-width: 600px) {
    width: 100%;
  }
}

.footer-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  padding: 0 rem(50px);
  background: url(../../images/footer-bg.svg) no-repeat right;
  background-size: contain;
  width: 100%;


  .left-side {
    svg {
      width: rem(81px);
      fill: var(--gray-three);
    }

    h4 {
      color: var(--main-white);
      font-size: rem(52px);
      font-weight: 500;
    }

    span {
      font-size: rem(14px);
      line-height: rem(22px);
      color: var(--gray-one);
    }

    @media (max-width: 600px) {
      h4 {
        font-size: rem(32px);
      }
    }
  }

  .right-side {
    justify-self: flex-end;
  }

  @media (max-width: 600px) {
    grid-template-columns: none;
  }
}

.social-media {
  display: flex;
  align-items: center;
  gap: rem(20px);

  a {
    background-color: var(--main-white);
    border-radius: rem(100px);
    width: rem(54px);
    height: rem(54px);
    padding: rem(20px);
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      opacity: .8;
      transition: opacity .5s;
    }
  }
}