@import "styles/functions.scss";

.partners {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  gap: rem(30px);

  @media (max-width: 600px) {
    justify-content: center;
  }
}

.partner-box {
  background-color: var(--main-white);
  border: rem(1px) solid var(--white-two);
  border-radius: rem(10px);
  display: flex;
  align-items: center;
  justify-content: center;
  width: rem(210px);
  height: rem(116px);
  overflow: hidden;

  img {
    max-width: 80%
  }

  @media (max-width: 600px) {
    zoom: 70%;
  }
}