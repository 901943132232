@import "styles/functions.scss";

.contact {
  padding-top: rem(50px);

  @media (max-width: 600px) {
    padding-bottom: rem(30px);
  }
}

.form-container {
  background-color: var(--main-black);
  width: rem(500px);
  min-height: rem(600px);
  margin: 0 auto;
  text-align: center;
  padding: rem(40px) rem(55px) rem(40px) rem(55px);
  height: 100%;
  border-radius: rem(10px);

  .title {
    font-size: rem(48px);
    letter-spacing: rem(4px);
  }

  @media (max-width: 600px) {
    width: 100%;

    .title {
      font-size: rem(32px);
    }
  }
}

.form {
  display: grid;
  align-items: center;
  padding-top: rem(45px);
  gap: rem(30px);
  width: 100%;

  .input {
    display: block;
    width: 100%;
    background: transparent;
    font-size: 18px;
    color: var(--main-white);
    line-height: 1.2;
    border: none;

    &:focus {
      border: none;
      outline: none;
    }

    &:hover {
      opacity: .8;
    }
  }


  input {
    padding: 0 rem(5px);
    height: rem(45px);
  }

  textarea {
    min-height: rem(115px);
    padding: rem(15px) rem(5px);
  }

  .input-container {
    border-bottom: rem(2px) solid var(--gray-two);
    position: relative;
  }

  .contact-button {
    display: flex;
    justify-content: center;
    padding-top: rem(40px);

    button {
      color: var(--main-black);
      background-color: var(--main-white);

      &:hover {
        border-color: var(--main-white);
        background-color: var(--main-black);
        color: var(--main-white);
      }
    }
  }
}

.border {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;

  &::before {
    content: "";
    display: block;
    position: absolute;
    bottom: rem(-2px);
    left: 0;
    width: 0;
    height: rem(2px);
    transition: all 0.4s;
    background: var(--main-white);
  }
}

.input:focus+.border::before {
  width: 100%;
}