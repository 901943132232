@import "styles/functions.scss";

.hero-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: rem(16px);
  margin: 4.375rem 0;

  .section-title {
    font-size: rem(72px);
    line-height: rem(91px);
    margin-bottom: rem(14px);

    @media (max-width: 600px) {
      line-height: rem(71px);
    }
  }

  .section-text {
    font-weight: bold;
  }

  @media (max-width: 600px) {
    flex-direction: column;

    .section-title {
      font-size: rem(48px);
    }

    svg {
      width: 100%;
      height: auto;
    }
  }
}

.what-section,
.how-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: space-between;
  gap: rem(34px);

  @media (max-width: 600px) {
    grid-template-columns: none;
  }
}


.what-section {
  @media (max-width: 600px) {
    margin-bottom: rem(34px);
  }
}

.what-section-skills,
.how-section-skills {
  display: flex;
  flex-wrap: wrap;
  gap: rem(20px);

  @media (max-width: 600px) {
    zoom: 65%;
    justify-content: center;
  }
}

.how-section-skills {
  flex-wrap: wrap-reverse;
  order: 2;
}

.clients-section {
  text-align: center;
  margin: 18.75rem 0 8.125rem 0;

  @media (max-width: 600px) {
    margin: 3.375rem 0 3.125rem 0;
  }
}