@import "styles/functions.scss";

.title {
  font-size: rem(22px);
  line-height: rem(30px);
  font-weight: 900;
  color: var(--gray-four);
  text-transform: uppercase;

  @media (max-width: 600px) {
    display: flex;
    justify-content: center;
  }
}