@import "styles/functions.scss";

.team-section {
  display: grid;
  text-align: center;
  justify-items: center;
  margin: 18.75rem 0 26.875rem 0;
  padding-top : 10rem;

  @media (max-width: 600px) {
    margin: 3.375rem 0 3.125rem 0;
  }
}

.team-members {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: rem(30px);
  margin: 1.25rem 0 4.375rem 0;

  @media (max-width: 600px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    zoom: 80%;
  }
}

.member-box {
  display: flex;
  flex-direction: column;
  flex: 1 1 260px;
  align-items: center;
}

.member-image {
  //border: rem(4px) solid var(--main-black);
  width: rem(170px);
  height: rem(170px);
  border-radius: rem(51px);
  margin-bottom: rem(20px);
  background-color: #eee;

  img {
    width: 100%;
    height: 100%;
    filter: grayscale(1);
    border-radius: 50px;
  }
}

.member-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: rem(5px);

  .member-name {
    font-size: rem(18px);
    font-weight: bold;
  }

  .member-job {
    font-size: rem(14px);
    font-weight: 500;
    color: var(--gray-two);
  }
}

.member-social {
  background-color: var(--gray-five);
  border-radius: rem(100px);
  padding: 0 rem(20px);
  width: auto;
  height: rem(36px);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: rem(9px);

  a {
    color: var(--gray-one);
    padding-bottom: rem(4px);
  }

  &:hover {
    opacity: .8;
    transition: opacity .3s;
  }
}